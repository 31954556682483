import { AfterViewInit, Component, contentChildren, ElementRef, inject, input, OnDestroy, output, signal, viewChild, ViewEncapsulation } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'

import { throttle } from 'lodash'
import { filter, first, Subscription } from 'rxjs'

import { IPaginatedListRes } from '@libs/payload'

import { animations } from '../../animations'
import { PageLimitLoader, ScrollListComponent } from '../scroll-list'
import { MasonryItemComponent } from './masonry-item.component'

@Component({
  selector: 'ace-masonry',
  templateUrl: './masonry.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: animations,
  exportAs: 'nanMasonry',
  standalone: true,
  imports: [ScrollListComponent]
})
export class MasonryComponent implements AfterViewInit, OnDestroy {
  elementRef = inject(ElementRef)

  gap = input(8)
  columns = input(2)
  loader = input.required<PageLimitLoader>()
  loading = input(false)
  valueChange = output<IPaginatedListRes<unknown>>()

  currentColumn = 0
  columnHeight: Array<number> = []

  scrollListRef = viewChild.required(ScrollListComponent)
  containerRef = viewChild.required<ElementRef<HTMLDivElement>>('containerRef')

  /**
   * 每次layout后通知高度的变化
   */
  waiting = signal(false)
  waiting$ = toObservable(this.waiting)

  masonryItems = contentChildren(MasonryItemComponent)
  masonryItems$ = toObservable(this.masonryItems)

  resizeObserver = new ResizeObserver(
    throttle(() => {
      // 重新布局
      this.masonryItems().forEach(item => (item.hasLayout = false))
      this.columnHeight = new Array(this.columns()).fill(0)
      this.currentColumn = 0
      this.layout()
    }, 100)
  )

  private _subscription = new Subscription()
  constructor() {}

  ngAfterViewInit() {
    this.columnHeight = new Array(this.columns()).fill(0)
    this._subscription.add(
      this.masonryItems$.subscribe(() => {
        this.layout()
      })
    )

    this.resizeObserver.observe(this.containerRef().nativeElement)
  }

  ngOnDestroy() {
    this._subscription.unsubscribe()
  }

  getContentHeight() {
    return new Promise<number>(resolve => {
      this.waiting$
        .pipe(
          filter(waiting => !waiting),
          first()
        )
        .subscribe(() => {
          resolve(Math.max(...this.columnHeight))
        })
    })
  }

  handleTemplateChange($event: IPaginatedListRes<unknown>) {
    this.waiting.set(true)
    this.valueChange.emit($event)
  }

  reset() {
    this.columnHeight = new Array(this.columns()).fill(0)
    this.currentColumn = 0
    this.scrollListRef().reset()
  }

  private layout() {
    const masonryList = this.masonryItems()
    const containerWidth = this.containerRef().nativeElement.getBoundingClientRect().width
    const columnWidth = (containerWidth - (this.columns() - 1) * this.gap()) / this.columns()

    for (let i = 0; i < masonryList.length; i++) {
      const item = masonryList[i]

      // 根据当前状态避免重新计算
      if (item.hasLayout) continue

      const width = item.width()
      const height = item.height()
      const renderHeight = height / (width / columnWidth)

      let minHeightIndex = 0
      const minHeight = this.columnHeight[0]
      for (let j = 0; j < this.columnHeight.length; j++) {
        const current = this.columnHeight[j]
        if (current < minHeight) {
          minHeightIndex = j
        }
      }
      this.currentColumn = minHeightIndex

      item.position.set({
        height: renderHeight,
        width: columnWidth,
        top: this.columnHeight[this.currentColumn] || 0,
        left: (columnWidth + this.gap()) * this.currentColumn
      })

      // 设置标识位
      item.hasLayout = true

      this.columnHeight[this.currentColumn] += renderHeight + this.gap()
    }

    this.waiting.set(false)
  }
}
