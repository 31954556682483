import { CommonModule } from '@angular/common'
import { Component, computed, input, signal } from '@angular/core'

import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader'

@Component({
  selector: 'ace-masonry-item',
  standalone: true,
  imports: [CommonModule, NgxSkeletonLoaderModule],
  template: `
    <div [style]="computedStyle()" class="absolute left-0 top-0 overflow-hidden transition-all duration-100">
      @if (loading()) {
        <ngx-skeleton-loader [theme]="skeletonTheme()"></ngx-skeleton-loader>
      } @else {
        <ng-content></ng-content>
      }
    </div>
  `
})
export class MasonryItemComponent {
  height = input.required<number>()
  width = input.required<number>()
  loading = input<boolean | null | undefined>(false)
  hasLayout = false

  position = signal<{ height: number; width: number; left: number; top: number }>({
    height: 0,
    width: 0,
    top: 0,
    left: 0
  })

  skeletonTheme = computed(() => {
    const { width, height } = this.position()
    return {
      width: width + 'px',
      height: height + 'px'
    }
  })

  computedStyle = computed(() => {
    const { left, top, width, height } = this.position()
    return {
      width: width + 'px',
      height: height + 'px',
      transform: `translate(${left}px, ${top}px)`
    }
  })
}
