import { CommonModule } from '@angular/common'
import { AfterViewInit, ChangeDetectionStrategy, Component, inject, input, OnDestroy, signal, viewChild } from '@angular/core'
import { toObservable } from '@angular/core/rxjs-interop'

import { produce } from 'immer'
import { finalize, fromEvent, map, merge, Observable, startWith, Subscription } from 'rxjs'
import { first } from 'rxjs/operators'

import { MasonryComponent } from '@libs/ng-shared/components/masonry'
import { MasonryItemComponent } from '@libs/ng-shared/components/masonry/masonry-item.component'
import { IPaginatedListRes, IResourceTemplate } from '@libs/payload'

import { ApiService } from '#core/services/api.service'

@Component({
  selector: 'ace-template-masonry',
  standalone: true,
  imports: [CommonModule, MasonryComponent, MasonryItemComponent],
  template: ` <div class="relative h-full w-full">
    <ace-masonry [gap]="gap()" [columns]="columns()" [loader]="templateLoader" (valueChange)="handleTemplateChange($event)" #masonryRef>
      @for (item of templateList(); track item.id) {
        <ace-masonry-item [width]="item.width" [height]="item.height" [loading]="item.loading | async">
          <img [src]="item.url" [alt]="item.id" class="border-primary-200 h-full w-full rounded-lg border object-cover" />
        </ace-masonry-item>
      }
    </ace-masonry>
  </div>`,
  styles: `
    :host {
      display: block;
      height: 100%;
      width: 100%;
    }
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateMasonryComponent implements AfterViewInit, OnDestroy {
  keywords = input('')
  columns = input(2)
  gap = input(8)
  apiService = inject(ApiService)
  templateList = signal<Array<{ id: string; width: number; height: number; url: string; loading: Observable<boolean> }>>([])

  masonryRef = viewChild.required(MasonryComponent)

  _subscription = new Subscription()

  keywords$ = toObservable(this.keywords)
  templateLoader = (pageNo: number, limit: number) => {
    return this.apiService.getTemplateList({ keyword: this.keywords(), page: pageNo, limit })
  }

  ngAfterViewInit() {
    this._subscription.add(
      this.keywords$.subscribe(() => {
        this.templateList.set([])
        // 触发loader
        this.masonryRef().reset()
      })
    )
  }

  ngOnDestroy() {
    this._subscription.unsubscribe()
  }

  handleTemplateChange($event: IPaginatedListRes<unknown>) {
    const dataList = $event.list as Array<IResourceTemplate>
    this.templateList.update(
      produce(list => {
        list.push(
          ...dataList.map(data => {
            let image: HTMLImageElement | undefined = new Image()
            image.src = data.snapshot
            return {
              id: data.id,
              width: data.width,
              height: data.height,
              url: data.snapshot,
              loading: merge(fromEvent(image, 'load'), fromEvent(image, 'error')).pipe(
                map(event => !event),
                first(),
                finalize(() => (image = undefined)),
                startWith(true)
              )
            }
          })
        )
      })
    )
  }
}
