import { Inject, Injectable } from '@angular/core'

import { merge } from 'lodash-es'
import { BehaviorSubject, Observable } from 'rxjs'

import { APP_CONFIG } from './config.constants'
import { AppConfig } from './config.types'

@Injectable({ providedIn: 'root' })
export class ConfigService {
  private _config: BehaviorSubject<AppConfig>

  /**
   * Constructor
   */
  constructor(@Inject(APP_CONFIG) config: AppConfig) {
    // Private
    this._config = new BehaviorSubject(config)
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for config
   */
  set config(value: AppConfig) {
    // Merge the new config over to the current config
    const config = merge({}, this._config.getValue(), value)

    // Execute the observable
    this._config.next(config)
  }

  // eslint-disable-next-line @typescript-eslint/member-ordering
  get config$(): Observable<AppConfig> {
    return this._config.asObservable()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Resets the config to the default
   */
  reset(): void {
    // Set the config
    this._config.next(this.config)
  }
}
