import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout'
import { Injectable } from '@angular/core'

import { fromPairs } from 'lodash-es'
import { map, Observable, ReplaySubject, switchMap } from 'rxjs'

import { ConfigService } from '../config'

@Injectable({ providedIn: 'root' })
export class MediaWatcherService {
  private _onMediaChange: ReplaySubject<{
    matchingAliases: string[]
    matchingQueries: Record<string, string>
  }> = new ReplaySubject<{
    matchingAliases: string[]
    matchingQueries: Record<string, string>
  }>(1)

  /**
   * Constructor
   */
  constructor(
    private _breakpointObserver: BreakpointObserver,
    private _configService: ConfigService
  ) {
    this._configService.config$
      .pipe(
        map(config => fromPairs(Object.entries(config.screens).map(([alias, screen]) => [alias, `(min-width: ${screen})`]))),
        switchMap(screens =>
          this._breakpointObserver.observe(Object.values(screens)).pipe(
            map(state => {
              // Prepare the observable values and set their defaults
              const matchingAliases: string[] = []
              const matchingQueries: Record<string, string> = {}

              // Get the matching breakpoints and use them to fill the subject
              const matchingBreakpoints = Object.entries(state.breakpoints).filter(([, matches]) => matches) ?? []
              for (const [query] of matchingBreakpoints) {
                // Find the alias of the matching query
                const matchingAliasEntry = Object.entries(screens).find(([, q]) => q === query)

                const matchingAlias = matchingAliasEntry ? matchingAliasEntry[0] : undefined

                // Add the matching query to the observable values
                if (matchingAlias) {
                  matchingAliases.push(matchingAlias)
                  matchingQueries[matchingAlias] = query
                }
              }

              // Execute the observable
              this._onMediaChange.next({
                matchingAliases,
                matchingQueries
              })
            })
          )
        )
      )
      .subscribe()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for _onMediaChange
   */
  get onMediaChange$(): Observable<{
    matchingAliases: string[]
    matchingQueries: Record<string, string>
  }> {
    return this._onMediaChange.asObservable()
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * On media query change
   *
   * @param query
   */
  onMediaQueryChange$(query: string | string[]): Observable<BreakpointState> {
    return this._breakpointObserver.observe(query)
  }
}
